const routesAdmin = {
  routes() {
    return [
      {
        path: "/admin/contents/",
        name: "contents",
        component: () => import("./gapp-components/components/common/content/Contents.vue")
      },
      {
        path: "/admin/content/:id",
        name: "content",
        component: () => import("./gapp-components/components/common/content/Content.vue")
      },
      {
        path: "/admin/chargeback",
        name: "approvalChargeback",
        component: () => import("./views/admin/Chargeback.vue"),
        meta: { privileges: ["APPROVAL_CREATE"] }
      },
      {
        path: "/admin/giftCards",
        name: "giftCards",
        component: () => import("./views/admin/GiftCards.vue")
      },
      {
        path: "/admin/giftCards/success",
        name: "giftCardsSuccess",
        component: () => import("./views/admin/GiftCardsSuccess.vue")
      },
      {
        path: "/admin/giftCards/cancelled",
        name: "giftCardsCancelled",
        component: () => import("./views/admin/GiftCardsCancelled.vue")
      },
      {
        path: "/admin/incentive",
        name: "approvalIncentive",
        component: () => import("./views/admin/Incentive.vue"),
        meta: { privileges: ["APPROVAL_CREATE"] }
      },
      {
        path: "/admin/registrations",
        name: "registrationManagement",
        component: () => import("./views/admin/RegistrationManagement.vue"),
        meta: { privileges: ["PARTICIPANT_UPDATE"] }
      },
      {
        path: "/admin/products",
        name: "products",
        component: () => import("./gapp-components/components/views/promotions/Products.vue"),
        meta: { privileges: ["PRODUCT_READ"] }
      },
      {
        path: "/admin/products/:id",
        name: "product",
        component: () => import("./gapp-components/components/views/promotions/Product.vue"),
        meta: { privileges: ["PRODUCT_READ"] }
      },
      {
        path: "/admin/promotionTypes",
        name: "promotionTypes",
        component: () => import("./gapp-components/components/views/promotions/PromotionTypes.vue"),
        meta: { privileges: ["PROMOTION_TYPE_READ"] }
      },
      {
        path: "/admin/promotionTypes/:id",
        name: "promotionType",
        component: () => import("./gapp-components/components/views/promotions/PromotionType.vue"),
        meta: { privileges: ["PROMOTION_TYPE_READ"] }
      },
      {
        path: "/admin/rule/formulas",
        name: "ruleFormulas",
        component: () => import("./gapp-components/components/views/rulesEngine/RuleFormulas.vue"),
        props: {
          title: "Promotion Formulas"
        },
        meta: { privileges: ["RULE_FORMULA_READ"] }
      },
      {
        path: "/admin/rule/formulas/:id",
        name: "ruleFormula",
        component: () => import("./gapp-components/components/views/rulesEngine/RuleFormula.vue"),
        props: {
          title: "Promotion Formulas"
        },
        meta: { privileges: ["RULE_FORMULA_READ"] }
      },
      {
        path: "/admin/promotions",
        name: "promotions",
        component: () => import("./gapp-components/components/views/promotions/Promotions.vue"),
        meta: { privileges: ["PROMOTION_READ"] }
      },
      {
        path: "/admin/promotions/:id",
        name: "promotion",
        component: () => import("./gapp-components/components/views/promotions/Promotion.vue"),
        meta: { privileges: ["PROMOTION_READ"] }
      },
      {
        path: "/admin/sales",
        name: "sales",
        component: () => import("./gapp-components/components/views/promotions/Sales.vue"),
        meta: { privileges: ["SALE_READ"] }
      },
      {
        path: "/admin/sales/:id",
        name: "sale",
        component: () => import("./gapp-components/components/views/promotions/Sale.vue"),
        meta: { privileges: ["SALE_READ"] }
      },
      {
        path: "/admin/saleTypes",
        name: "saleTypes",
        component: () => import("./gapp-components/components/views/promotions/SaleTypes.vue"),
        meta: { privileges: ["SALE_TYPE_READ"] }
      },
      {
        path: "/admin/saleTypes/:id",
        name: "saleType",
        component: () => import("./gapp-components/components/views/promotions/SaleType.vue"),
        meta: { privileges: ["SALE_TYPE_READ"] }
      },
      {
        path: "/admin/payouts",
        name: "payouts",
        component: () => import("./gapp-components/components/views/promotions/Payouts.vue"),
        meta: { privileges: ["PAYOUT_READ"] }
      },
      {
        path: "/admin/payouts/:id",
        name: "payout",
        component: () => import("./gapp-components/components/views/promotions/Payout.vue"),
        meta: { privileges: ["PAYOUT_READ"] }
      },
      {
        path: "/admin/adjustments",
        name: "adjustments",
        component: () => import("./gapp-components/components/views/transactions/Adjustments.vue"),
        meta: { privileges: ["PAYOUT_CREATE"] }
      },
      {
        path: "/admin/courses",
        name: "courses",
        component: () => import("./gapp-components/components/views/promotions/Courses.vue"),
        meta: { privileges: ["COURSE_READ"] }
      },
      {
        path: "/admin/courses/:id",
        name: "course",
        component: () => import("./gapp-components/components/views/promotions/Course.vue"),
        meta: { privileges: ["COURSE_READ"] }
      },
      {
        path: "/admin/courseGrades",
        name: "courseGrades",
        component: () => import("./gapp-components/components/views/promotions/CourseGrades.vue"),
        meta: { privileges: ["COURSE_GRADE_READ"] }
      },
      {
        path: "/admin/courseGrades/:id",
        name: "courseGrade",
        component: () => import("./gapp-components/components/views/promotions/CourseGrade.vue"),
        meta: { privileges: ["COURSE_GRADE_READ"] }
      },
      {
        path: "/admin/approvalTypes",
        name: "approvalTypes",
        component: () => import("./gapp-components/components/views/promotions/ApprovalTypes.vue"),
        meta: { privileges: ["APPROVAL_TYPE_READ"] }
      },
      {
        path: "/admin/approvalTypes/:id",
        name: "approvalType",
        component: () => import("./gapp-components/components/views/promotions/ApprovalType.vue"),
        meta: { privileges: ["APPROVAL_TYPE_READ"] }
      },
      {
        path: "/admin/approvals",
        name: "approvals",
        component: () => import("./gapp-components/components/views/promotions/Approvals.vue"),
        meta: { privileges: ["APPROVAL_READ"] }
      },
      {
        path: "/admin/approvals/:id",
        name: "approval",
        component: () => import("./gapp-components/components/views/promotions/Approval.vue"),
        meta: { privileges: ["APPROVAL_READ"] }
      },
      {
        path: "/admin/myCompanyManagement",
        name: "myCompanyManagement",
        component: () => import("./views/admin/MyCompanyManagement.vue"),
        meta: { privileges: ["ORGANIZATION_READ"] }
      },
      {
        path: "/admin/companyManagement",
        name: "companyManagement",
        component: () => import("./views/admin/CompanyManagement.vue"),
        meta: { privileges: ["ORGANIZATION_READ"] }
      },
      {
        path: "/admin/cancellations",
        name: "cancellations",
        component: () => import("./views/admin/Cancellations.vue"),
        meta: { privileges: ["PARTICIPANT_READ"] }
      },
      {
        path: "/admin/participantManagement",
        name: "participantManagement",
        component: () => import("./views/admin/ParticipantManagement.vue"),
        meta: { privileges: ["PARTICIPANT_READ"] }
      },
      {
        path: "/admin/vinTransferManagement",
        name: "vinTransferManagement",
        component: () => import("./views/admin/VinTransferManagement.vue")
      }
    ];
  }
};

export default routesAdmin;
