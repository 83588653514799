import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#69be28", // Green - #69be28
        secondary: "#454548", // Grey - #454548
        accent: "#69be28", // Green - #69be28
        error: "#f08282", // Pulled from accepted text colour palette
        info: "#888", // Green - #69be28
        success: "#40811F", // Custom success green - #40811F
        warning: "#E60012" // Kawasaki red - #E60012
      },
      dark: {
        primary: "#69be28", // Green - #69be28
        secondary: "#ffffff", // White
        accent: "#69be28", // Green - #69be28
        error: "#f08282", // Pulled from accepted text colour palette
        info: "#888", // Green - #69be28
        success: "#40811F", // Custom success green - #40811F
        warning: "#E60012" // Kawasaki red - #E60012
      }
    }
  }
});
