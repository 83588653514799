import Vue from "vue";
import VueRouter from "vue-router";
import routesAdmin from "./routesAdmin";
import routesCommonAuthenticated from "./routesCommonAuthenticated";
import routesCommonPublic from "./routesCommonPublic";
import routesReports from "./routesReports";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("./views/LayoutAuthenticated.vue"),
    meta: {
      requiresAuthentication: true
    },
    children: [
      // Common authenticated routes
      ...routesCommonAuthenticated.routes(),

      // Admin routes
      ...routesAdmin.routes(),

      // Report routes
      ...routesReports.routes(),

      // Dashboard
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("./views/Dashboard.vue")
      },
      {
        path: "/accounts",
        name: "myAccounts",
        component: () => import("./views/accounts/MyAccounts.vue"),
        meta: {
          participantTypes: ["400"]
        }
      },
      {
        path: "/accounts/:id",
        name: "myAccountsWithId",
        component: () => import("./views/accounts/MyAccounts.vue"),
        meta: {
          participantTypes: ["300"]
        }
      },
      {
        path: "/accounts/managers",
        name: "myAccountManagers",
        component: () => import("./views/accounts/MyAccountManagers.vue"),
        meta: {
          participantTypes: ["300"]
        }
      },
      {
        path: "/earnings",
        name: "salespersonEarnings",
        component: () => import("./views/salesperson/SalespersonEarnings.vue")
      },

      // Sales paths
      {
        path: "/sales/rulesAndFaqs",
        name: "salesRulesAndFaqs",
        component: () => import("./views/salesperson/SalespersonRulesAndFaqs.vue")
      },

      // Racer paths
      {
        path: "/racer/results",
        name: "racerResults",
        component: () => import("./views/racer/RacerResults.vue"),
        meta: {
          participantTypes: ["400"]
        }
      },
      {
        path: "/racer/rulesAndFaqs",
        name: "racerRulesAndFaqs",
        component: () => import("./views/racer/RacerRulesAndFaqs.vue")
      },
      { path: "/surveys/", name: "surveys", component: () => import("./views/common/survey/Surveys.vue") },
      { path: "/surveys/:id", name: "survey", component: () => import("./views/common/survey/Survey.vue") }
    ]
  },
  {
    path: "/",
    component: () => import("./views/LayoutPublicThin.vue"),
    children: [
      // Common public routes
      ...routesCommonPublic.routes(),

      {
        path: "/views/preEnroll",
        name: "pre-enroll",
        component: () => import("./views/PreEnroll.vue")
      }
    ]
  },
  //  Register
  {
    path: "/register",
    component: () => import("./views/registration/LayoutPublicRegistration"),
    children: [
      {
        path: "/",
        name: "register",
        component: () => import("./views/registration/Registration.vue")
      },
      {
        path: "/pending-consent",
        name: "pendingConsent",
        component: () => import("./views/racer/PendingConsentNotification.vue"),
        meta: {
          requiresAuthentication: true
        }
      },
      {
        path: "/join/racer",
        name: "racerJoin",
        component: () => import("./views/racer/RacerJoin.vue"),
        meta: {
          requiresAuthentication: true
        }
      },
      {
        path: "/join/salesperson",
        name: "salespersonJoin",
        component: () => import("./views/salesperson/SalespersonJoin.vue"),
        meta: {
          requiresAuthentication: true
        }
      }
    ]
  },
  {
    path: "/",
    component: () => import("./views/LayoutPublicWide.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("./views/common/Login.vue")
      },
      {
        path: "/children-privacy-policy",
        name: "ChildrenPrivacyPolicy",
        component: () => import("./views/footer/ChildrenPrivacyPolicy.vue")
      }
    ]
  },
  {
    path: "/",
    component: () => import("./views/LayoutComingSoon.vue"),
    children: [
      {
        path: "/coming-soon",
        name: "coming-soon",
        component: () => import("./views/ComingSoon.vue")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

router.onError(error => {
  if (error.name === "ChunkLoadError" || /Loading chunk \d+ failed/g.test(error.message)) {
    window.location.reload();
  }
});

export default router;
