/**
 * Service to check Program
 */

import ApiService from "@/gapp-components/services/api.service";
import moment from "moment";

let instanceRouter = null;
const KawasakiService = {
  init(router) {
    instanceRouter = router;
  },

  isRegionalSalesDirector() {
    let participant = instanceRouter.app.$store.getters.selectedParticipant;
    return participant && participant.participantType && participant.participantType.participantTypeKey == "210";
  },

  isDistrictManager() {
    let participant = instanceRouter.app.$store.getters.selectedParticipant;
    return participant && participant.participantType && participant.participantType.participantTypeKey == "220";
  },

  isStaffOrEmployee() {
    let participant = instanceRouter.app.$store.getters.selectedParticipant;
    return participant && participant.participantType && participant.participantType.participantTypeKey == "230";
  },

  isDealerPrincipalOrDealership() {
    let participant = instanceRouter.app.$store.getters.selectedParticipant;
    return participant && participant.participantType && participant.participantType.participantTypeKey == "240";
  },

  /** Program Registration Date - this field includes the registration date for both Racer or Sale program */
  isActiveInProgram() {
    let participant = instanceRouter.app.$store.getters.selectedParticipant;
    return (
      participant &&
      participant.customFieldValue1 &&
      moment(participant.customFieldValue1).isSameOrBefore(moment()) &&
      participant.customFieldValue2 &&
      participant.customFieldValue2 == "true"
    );
  },

  /** Racer Methods */

  isRacer() {
    let participant = instanceRouter.app.$store.getters.selectedParticipant;
    return participant && participant.participantType && participant.participantType.participantTypeKey == "400";
  },
  hasActiveRacerParticipant() {
    let availableParticipants = instanceRouter.app.$store.getters.availableParticipants;
    if (availableParticipants) {
      let racer = availableParticipants.find(
        p => p.participantType.participantTypeKey == "400" && p.status.name == "ENR"
      );
      return racer && racer.id;
    }
    return false;
  },

  /** Salesperson Methods */

  isSalesperson() {
    let participant = instanceRouter.app.$store.getters.selectedParticipant;
    return participant && participant.participantType && participant.participantType.participantTypeKey == "300";
  },

  hasActiveSalespersonParticipant() {
    let availableParticipants = instanceRouter.app.$store.getters.availableParticipants;
    if (availableParticipants) {
      let salesperson = availableParticipants.find(
        p => p.participantType.participantTypeKey == "300" && p.status.name == "ENR"
      );
      return salesperson && salesperson.id;
    }
    return false;
  },

  isParentConsentInitialized() {
    const participant = instanceRouter.app.$store.getters.selectedParticipant;

    return Boolean(participant.user?.externalConsentIdentifier);
  },

  async hasParentConsents() {
    let participant = instanceRouter.app.$store.getters.selectedParticipant;

    if (!this.isUnder18(participant.user.birthDate)) return true;

    if (!this.isParentConsentInitialized()) return false;

    try {
      let { data } = await ApiService.get(`/api/privo/consent/status/${participant.id}/public`);

      return data?.toLowerCase() === "approved";
    } catch (error) {
      console.log("Error fetching parental consent status: ", error);
      return false;
    }
  },

  isUnder18(date) {
    if (!date) {
      return false;
    }
    const birthDate = moment(date);
    const today = moment();
    const age = today.diff(birthDate, "years");
    return age < 18;
  },

  async saveParticipantVehicles(vehicles) {
    const newVehicles = vehicles.existing.filter(v => !v.id);
    let postPromises = newVehicles.map(vehicle => {
      const productRegistrationForm = {
        productRegistrationItems: [
          {
            product: vehicle.product,
            serialNumber: vehicle.serialNumber
          }
        ]
      };
      ApiService.post("/api/productRegistrations/submit", productRegistrationForm);
    });
    const deleteRegistrationPromises = vehicles.delete.map(vehicle =>
      ApiService.delete(`/api/productRegistrations/${vehicle.registrationId}/delete`)
    );
    await Promise.all([...deleteRegistrationPromises, ...postPromises]);
  },

  async createReloadableCard(awardVehicleKey) {
    // Don't issue cards for non-racer or non-salesperson participants
    if (!this.isRacer() && !this.isSalesperson()) {
      console.log("Not a racer or salesperson. No reloadable card will be issued.");
      return;
    }

    let participant = instanceRouter.app.$store.getters.selectedParticipant;

    const avs = await instanceRouter.app.$awardvehicle.getAvailableAwardVehicles(participant);
    let awardVehicle = avs.find(av => av.awardVehicleKey === awardVehicleKey);

    if (!awardVehicle) {
      throw new Error("Award Vehicle not found.");
    }

    const cardResponse = await ApiService.post(
      `/api/prepaidTechnologiesCards/awardVehicle/${awardVehicle.id}/card/create`
    );

    return cardResponse.data;
  },

  async saveParticipantDealers(dealers) {
    let participant = instanceRouter.app.$store.getters.selectedParticipant;
    const deletePromises = dealers.delete.map(org => {
      if (org.relationshipId && org.relationshipId > 0) {
        return ApiService.delete(`/api/relatedOrganizations/${org.relationshipId}`);
      } else if (org.isPrimary) {
        return ApiService.patch(`/api/participants/${participant.id}`, {
          organization: null,
          customFieldValue16: ""
        });
      }
      return Promise.resolve();
    });
    await Promise.all([...deletePromises]);

    let relatedOrganizations = dealers.existing.map(org => ({
      relatedOrganization: ApiService.getSelfUrl("organization", org),
      participant: ApiService.getSelfUrl("participant", participant),
      description: org.jobTitle,
      dirty: org.dirty,
      isPrimary: org.isPrimary,
      relationshipId: org.relationshipId
    }));

    await this.updatePrimaryDealer(relatedOrganizations);

    let postPromises = relatedOrganizations
      .filter(dealer => dealer.dirty)
      .map(relatedOrganization => ApiService.post("/api/relatedOrganizations", relatedOrganization));

    await Promise.all([...postPromises]);
  },

  async updatePrimaryDealer(relatedOrganizations) {
    let participant = instanceRouter.app.$store.getters.selectedParticipant;
    let primaryDealer = relatedOrganizations.find(dealer => dealer.isPrimary && dealer.dirty);
    primaryDealer ??= relatedOrganizations[0];
    if (primaryDealer) {
      if (primaryDealer.relationshipId && primaryDealer.relationshipId > 0) {
        try {
          await ApiService.delete(`/api/relatedOrganizations/${primaryDealer.relationshipId}`);
        } catch (error) {
          console.error("Error deleting primary dealer relationship:", error);
        }
      }
      const primaryDealerIndex = relatedOrganizations.indexOf(primaryDealer);
      if (primaryDealerIndex > -1) {
        relatedOrganizations.splice(primaryDealerIndex, 1);
      }
    }
    if (primaryDealer) {
      const participantForm = {
        customFieldValue16: primaryDealer.description,
        organization: primaryDealer.relatedOrganization
      };
      await ApiService.patch(`/api/participants/${participant.id}`, participantForm);
    }
  },

  async getParticipantTypeForSalespersonAndRacer() {
    const { data } = await ApiService.post("/api/participantTypes/search?size=1", { participantTypeKey: 350 });
    return data.content[0];
  },

  async updateParticipantType(participantType) {
    let participant = instanceRouter.app.$store.getters.selectedParticipant;
    await ApiService.patch("/api/participants/" + participant.id, {
      participantType: ApiService.getSelfUrl("participantType", participantType)
    });
  },

  async registerSalespersonAndIssueCard(organizations, participantTypeKey) {
    try {
      await this.saveParticipantDealers(organizations);
      const joinForm = {
        customFieldValue1: moment(),
        customFieldValue2: true,
        statusName: participantTypeKey !== "300" ? "PRE" : null
      };
      const updatedParticipant = await this.joinSalespersonProgram(joinForm);
      const { data } = await ApiService.post("/api/participantTypes/search?size=1", {
        participantTypeKey
      });
      const participantType = data.content[0];
      await this.updateParticipantType(participantType);
      const emailTemplateForm = this.buildSalesEmailTemplate(updatedParticipant);
      try {
        await ApiService.post("/api/emailTemplates/sendToParticipant", emailTemplateForm);
      } catch (err) {
        console.error("Error sending salesperson join email:", err);
      }
      try {
        await this.createReloadableCard("SALESPERSON_PAYOUTS");
      } catch (cardError) {
        console.error("Error issuing card:", cardError);
        throw {
          msg: "CARD_ERROR",
          participant: updatedParticipant,
          originalError: cardError
        };
      }
      return {
        success: true,
        participant: updatedParticipant
      };
    } catch (error) {
      console.error("Error registering salesperson:", error);
      throw error;
    }
  },

  async updateSalespersonDealers(organizations, participantTypeKey) {
    try {
      await this.saveParticipantDealers(organizations);
      const { data } = await ApiService.post("/api/participantTypes/search?size=1", {
        participantTypeKey
      });
      const participantType = data.content[0];
      await this.updateParticipantType(participantType);
      return { success: true };
    } catch (error) {
      console.error("Error updating salesperson dealers:", error);
      throw error;
    }
  },

  buildSalesEmailTemplate(participant) {
    const emailTemplateForm = { participant: { id: participant.id } };

    if (participant?.status?.name === "ENR") {
      emailTemplateForm.emailTemplateKey = "SALES_REWARDS_JOIN";
    } else if (participant?.status?.name === "PRE") {
      emailTemplateForm.emailTemplateKey = "SALES_REWARDS_PENDING";
    }

    return emailTemplateForm;
  }
};

export default KawasakiService;
