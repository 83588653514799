const routesReports = {
  routes() {
    return [
      {
        path: "/reports",
        name: "reports",
        component: () => import("./gapp-components/components/views/reports/Reports.vue")
      },
      {
        path: "/reports/allClaimTypesReporting",
        name: "allClaimTypesReporting",
        component: () => import("./views/reports/AllClaimTypesReporting.vue"),
        props: true
      },
      {
        path: "/reports/claimReporting",
        name: "claimReporting",
        component: () => import("./views/reports/ClaimReporting.vue")
      },
      {
        path: "/reports/customReport/:id",
        name: "customReport",
        component: () => import("./views/reports/CustomReport.vue")
      },
      {
        path: "/reports/enrollmentReporting",
        name: "enrollmentReporting",
        component: () => import("./views/reports/EnrollmentReporting.vue")
      },
      {
        path: "/reports/reportDownloads",
        name: "reportDownloads",
        component: () => import("./views/reports/ReportDownloads.vue")
      },
      {
        path: "/reports/:id",
        name: "report",
        component: () => import("./gapp-components/components/views/reports/Report.vue")
      }
    ];
  }
};

export default routesReports;
