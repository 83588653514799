<template>
  <router-view></router-view>
</template>

<style lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/style.scss";
</style>

<script>
export default {
  name: "App",
  metaInfo: {
    title: "Default",
    titleTemplate: "%s | Kawasaki Rewards Program"
  },
  mounted() {
    let oneTrustScriptId = process.env.VUE_APP_ONE_TRUST_SCRIPT_ID;
    if (oneTrustScriptId) {
      // OneTrust JavaScript for Cookie Consent
      const cookieLawScript = document.createElement("script");
      cookieLawScript.type = "text/javascript";
      cookieLawScript.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
      cookieLawScript.setAttribute("data-domain-script", oneTrustScriptId);
      document.head.appendChild(cookieLawScript);

      // OneTrust JavaScript for Consent Management
      const wrapperScript = document.createElement("script");
      wrapperScript.type = "text/javascript";
      wrapperScript.text = "function OptanonWrapper() { }";
      document.head.appendChild(wrapperScript);
    }
  }
};
</script>
